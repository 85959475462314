
.masonry {
  --gridGap: 0.5rem;
  display: grid;
  grid-gap: var(--gridGap);
  grid-template-columns: repeat( auto-fit, minmax(10rem, 1fr) );
  white-space: nowrap;
    
  &.rendered {
    display: block;
    overflow-x: hidden;
    padding-right: 0;
  }

  .masonry-col {
    display: inline-block;
    vertical-align: top;
    margin-right: var(--gridGap);

    &:last-child {
      margin-right: 0;
    }
  }

  .masonry-col > * {
    margin-bottom: var(--gridGap);

    &:last-child {
      margin-bottom: 0;
    }
  }
}