.publPage .publNav .publMenuItem.Button.link {
  flex-direction: column;
  font-size: initial!important;
  align-items: flex-start;
  justify-content: center;
  height: 6rem;

  .avatar {
    border-radius: 50%;
    width: 5rem;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
  }

  h2 {
    border: unset;
    font-size: 1em;
    font-weight: 400;
    color: black;
    text-transform: initial;
    margin: 0 2rem 0 5rem;
    text-align: left;
  }
  h3 {
    font-size: 1em;
    font-weight: 200;
    color: black;
    text-transform: initial;
    margin: 0 2rem 0 5rem;
    text-align: left;
    font-family: "Savoye LET";
  }
}