:root {
  --publNavColor: #B4C8CA;
  --publNavColorHover: #96c0c4;
  --publNavColorMuted: #B4C8CA88;
  --publPriceListBorderColor: #B4C8CA55;
  --publIntroBG: #fffe;
  --publFooterHeight: 3rem;
}


.page.publPage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-y: scroll;
  justify-content: center;
  //background-color: var(--pageBg);

  // Make sure we fill the height of the page
  min-height: fill-available;

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
}

.page.publPage {
  a {
    color: var(--publNavColor);

    &:hover {
      color: var(--publNavColorHover);
    }
  }
}

.publPage .publNavBack {
  display: none; // Only shown on mobile
  position: sticky;
  top: 0;
  width: 100%;
  flex-shrink: 0;
  z-index: 1;
  background-color: var(--publIntroBG);

  .Button.link {
    position: relative;
    width: 100%;
    justify-content: left;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 2em;
    color: var(--publNavColor);
    padding-left: 2em;
    text-transform: uppercase;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border: 1rem solid transparent;
      border-right: 1rem solid var(--publNavColor);
      opacity: 0.5;
    }

    &:hover {
      color: var(--publNavColorHover);

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border: 1rem solid transparent;
        border-right: 1rem solid var(--publNavColorHover);
      }
    }
  }

}

.publPage .publNav {
  flex-shrink: 0;
  padding: 0.5rem 0 1rem;
  position: sticky;
  top: 0;
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  h2 {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 1.65em;
    color: var(--publNavColorMuted);
    padding: 0.5em 0;
    text-transform: uppercase;
    border-top: 2px solid var(--publNavColor);
    margin-bottom: 0;
  }

  .Button.link {
    position: relative;
    width: 100%;
    justify-content: left;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 2em;
    color: var(--publNavColor);
    padding-right: 2em;
    text-transform: uppercase;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border: 1rem solid transparent;
      border-left: 1rem solid var(--publNavColor);
      opacity: 0.5;
    }

    &:hover {
      color: var(--publNavColorHover);

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        border: 1rem solid transparent;
        border-left: 1rem solid var(--publNavColorHover);
      }
    }
  }

}

.publPage .publHero,
.publPage .publMobileHero {
  position: relative;
  flex-shrink: 0;

  .publLogo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(calc(-50% - 1rem));
    max-width: 30rem;
    width: 100%;
    margin: 1rem;
    color: white;
  }
}

.publMain {
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  background-color: #fff;
}

.publContentNav,
.publContent {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  padding-top: 1px; // To make sure margin-top of first child starts from top of this container

  h1,
  h2,
  h3 {
    color: var(--publNavColor);
    text-transform: uppercase;
    margin: 0;
    font-weight: 600;
    font-size: 1.25em;
    text-align: center;
  }

  @media (max-width: 576px) {
    font-size: 1.2em;
  }
}

.publContentNav,
.publIntro {
  p {
    text-align: center;
    font-size: 0.85em;
    line-height: 1.9em;
  }
}

@media (min-width: 850px) {
  .publIntro {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    background-color: var(--publIntroBG);
    padding: 0.5rem 1rem;
    min-height: 8rem;
    min-width: 15rem;
    max-width: 50%;
  }
}

.publContent {
  padding-bottom: calc(2rem + var(--publFooterHeight));

  .Mobiledoc {
    max-width: var(--maxFormWidth);
    margin: 2rem auto;
    padding-left: var(--padHoriz);
    padding-right: var(--padHoriz);

    .MediaCard {
      margin-left: var(--padHorizNeg); 
      margin-right: var(--padHorizNeg); 
    }

  }

  @media (max-width: 576px) {
    padding-bottom: var(--gapVert);
  }
}

.publContentNav {
  display: none; // Only visible on mobile
  padding-bottom: 1rem;
}

.publFooter {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 2px solid var(--publNavColor);

  ul {
    list-style: none;
    margin: 0;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
  }

  @media (max-width: 576px) {
    .publMain & {
      display: none;
    }
  }
}

.mobileFooter {
  display: none;
  margin-top: var(--gapVert);
  width: 100%;

  @media (max-width: 576px) {
    display: block;
    
    .publFooter {
      display: block;
      bottom: unset;
      right: unset;
      position: relative;
      margin-top: var(--gapVert);
      
      ul {
        display: block;
      }
    }

  }
}

// Page navigation animation
.publPageNav {
  &-enter .publMain {
    opacity: 0.2;
  }
  &-enter-active .publMain {
    transition: opacity 1s ease-out;
  }
  &-enter-end .publMain {
    opacity: 1;
  }
  /* ----------------------------- */
  &-leave .publMain {
    opacity: 1;
    transform: translateX(0);
  }
  &-leave-active {
    position: absolute;
    top: 0;
    width: 100%;
    .publMain {
      transition: opacity .3s ease-out;
    }
  }
  &-leave-end .publMain {
    opacity: 0;
  }
}

/* Mobile navigation */
@media (max-width: 576px) {
  // Hide nav on all pages
  .App .page.publPage {
    .publNav {
      display: none;
    }
    .publNavStart {
      display: none; // Don't show on mobile, using .publNavBack instead
    }
    .publNavBack {
      display: block; // Back navigation on mobile
    }
    .publContentNav {
      display: block; // Start page content on mobile
    }

  }

  // Show nav on root page and hide content
  .App .page.publPage.isSiteRoot {
    .publNav {
      display: block;
      position: relative;
      width: 100%;
      padding-top: 0;

      .publMobileHero {
        margin-bottom: 0.5rem;
      }
      .publIntro {
        display: block;
      }
    }
    .publMain {
      display: none;
    }
  }

  // Page navigation animation MOBILE
  .publPageNav {
    &-enter .publMain {
      opacity: 0.5;
      transform: translateX(100%);
    }
    &-enter-active .publMain {
      transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    }
    &-enter-end .publMain {
      opacity: 1;
      transform: translateX(0);
    }
    /* ----------------------------- */
    &-leave .publMain {
      opacity: 1;
      transform: translateX(0);
    }
    &-leave-active {
      position: absolute;
      top: 0;
      width: 100%;
      .publMain {
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
      }
    }
    &-leave-end .publMain {
      opacity: 0.5;
      transform: translateX(-100%);
    }
  }

  .publPageNavBack {
    &-enter .publMain {
      opacity: 0.5;
    }
    &-enter-active .publMain {
      transition: opacity 0.5s ease-out;
    }
    &-enter-end .publMain {
      opacity: 1;
    }
    /* ----------------------------- */
    &-leave .publMain {
      opacity: 1;
      transform: translateX(0);
    }
    &-leave-active {
      position: absolute;
      z-index: 9;
      top: 0;
      width: 100%;
      .publMain {
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
      }
    }
    &-leave-end .publMain {
      opacity: 0.5;
      transform: translateX(100%);
    }
  }
}  
