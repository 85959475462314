.AdminMenu,
.MainMenu {
  --linkHover: #dfe7fa;
  
  .NavStateButton {
    color: black;
    font-weight: 300;

    &:hover {
      background-color: var(--linkHover);
    }    
  }
}

.AdminMenu {
  position: sticky;
  flex-shrink: 0;
  z-index: 9;
  top: 0;
  left: 0;
  height: var(--headerHeight);
  width: 100%;
  box-shadow: var(--shadow);
  background: var(--pageBg);

  .AvatarWrapper {
    flex-shrink: 0;
  }
  .Avatar {
    border-radius: 50%;
    height: calc(var(--headerHeight) - var(--padVert));
    margin: calc(var(--padVert) / 2);
    width: auto;
  }
}

.MainMenu {
  display: flex;
  max-width: var(--maxHeaderWidth);
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  
  .MainMenu-Slot {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    &:first-child > span { margin-right: auto; }
    &:last-child  > span { margin-left: auto;  }
  }

  .NavSlot {
    width: 0;
    z-index: 9;
    font-size: 1.25em;
  }
}

/**
 * Animations
 */

.NavBar {
  &-enter {
    opacity: 0;
    
  }
  &-enter-active {
    transition: opacity 0.5s ease-in;
  }
  &-enter-end {
    opacity: 1;
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: opacity 0.3s ease-in;
  }
  &-leave-end {
    opacity: 0;
  }
}
