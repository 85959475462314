.Dropdown-Wrapper {
  position: relative;
  line-height: 1; // makes sure the icon container doesn't overflow parent causing a permanent scrollbar
}

.Dropdown {
  --dropdownBg: var(--pageBg);
  --dropdownBorderColor: var(--borderMutedColor);
  --dropdownSelect: var(--linkHover);
  --dropdownHoverBg: var(--linkFg);
  --dropdownHoverFg: var(--dropdownBg);
  --dropdownPadVert: var(--padVert);
  --dropdownPadHoriz: var(--padHoriz);
  --dropdownSpacer: 0px;
  --dropdownBorderRadius: var(--borderRadiusSmall);
  
  display: flex;
  flex-direction: column;
  position: absolute;

  background-color: var(--dropdownBg);
  border: 1px solid var(--dropdownBorderColor);

  .item {
    border-width: 0;
    border-radius: 0;
    padding: var(--dropdownPadVert) var(--dropdownPadHoriz);
    background-color: var(--dropdownBg);
    cursor: pointer;

    &.isFocused {
      background-color: var(--dropdownSelect);
    }
    &:hover {
      color: var(--dropdownHoverFg);
      background-color: var(--dropdownHoverBg);
    }
  }

  button {
    text-align: left;
  }

  &.placeBottomLeft {
    bottom: 0;
    left: 0;
    transform: translate(0, calc(100% + var(--dropdownSpacer)));
  }
  &.placeBottomCenter {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + var(--dropdownSpacer)));
  }
  &.placeBottomRight {
    bottom: 0;
    right: 0;
    transform: translate(0, calc(100% + var(--dropdownSpacer)));
  }
  
  &.placeTopLeft {
    top: 0;
    left: 0;
    transform: translate(0, calc(-100% - var(--dropdownSpacer)));
    border-top-left-radius: var(--dropdownBorderRadius);
    border-top-right-radius: var(--dropdownBorderRadius);
  }
  &.placeTopCenter {
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% - var(--dropdownSpacer)));
    border-top-left-radius: var(--dropdownBorderRadius);
    border-top-right-radius: var(--dropdownBorderRadius);
  }
  &.placeTopRight {
    top: 0;
    right: 0;
    transform: translate(0, calc(-100% - var(--dropdownSpacer)));
    border-top-left-radius: var(--dropdownBorderRadius);
    border-top-right-radius: var(--dropdownBorderRadius);
  }

  &.placeLeftCenter {
    top: 50%;
    left: 0;
    transform: translate(calc(-100% - var(--dropdownSpacer)), -50%);
  }

  &.placeRightCenter {
    top: 50%;
    right: 0;
    transform: translate(calc(100% - var(--dropdownSpacer)), -50%);
  }
}

/**
 * Animate rows and list rows
 */
 .Dropdown--Animation {
   &-leave {
     opacity: 1;
   }
 
   &-leave-active {
     overflow: hidden;
     transition: height 0.1s ease-out, opacity 0.1s ease-in;
   }
   
   &-leave-end {
     height: 0;
     opacity: 0;
   }
   
   &-enter {
     height: 0;
     opacity: 0;
   }
   
   &-enter-active {
     transition: height 0.1s ease-out, opacity 0.1s ease-in;
   }
   
   &-enter-end {
     opacity: 1;
   }
 }