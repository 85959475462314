.GalleryLayoutField {
  .StateButton {
    svg {
      color: #ccc;
      width: 3rem;
      height: 3rem;
    }
    
    &:hover svg,
    &.isSelected {
      border-color: transparent;
      svg {
        color: var(--primaryBg);
      }
    }
  }
}