:root {
  // Colors
  --primaryBg: #956EA2;
  --primaryFg: white;
  --primaryHover: #B574CA;
  --secondaryBg: #B4C8CA;
  --secondaryFg: white;
  --secondaryHover: #84989A;
  --linkBg: transparent;
  --linkFg: var(--primaryBg);
  --linkHover: #EEF0F5;

  --errorBg: rgb(237, 159, 159);
  --errorFg: var(--primaryFg);
}
