/*
  StickyRow
*/
.sticky-row-wrapper {
  .sticky-row {
    position: absolute;
    z-index: 9;
  }
  .row--hidden {
    visibility: hidden!important;
  }
  .sticky-row--hidden {
    display: none!important;
  }
}