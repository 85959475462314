/* Main Colour Variables scoped to :root */

:root {
  font-size: 16px;
  // Colors
  --primaryBg: #5591FF;
  --primaryFg: white;
  --primaryHover: #307AFF;
  --secondaryBg: #AFAFAF;
  --secondaryFg: white;
  --secondaryHover: #939393;
  --linkBg: transparent;
  --linkFg: var(--primaryBg);
  --linkHover: #EEF0F5;

  --errorBg: rgb(237, 159, 159);
  --errorFg: var(--primaryFg);
  
  --borderMutedColor: #ddd;
  --inputBorderColor: #eee;
  --inputRowLineColor: #ddd;
  --listItemHoverColor: rgba(0,0,0, 0.025);
  
  --shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
  --shadowMuted: 0px 1px 0px 0px rgba(0,0,0,0.1);
  
  // General
  --pageBg: white;
  --stickyHeaderBg: rgba(255,255,255,0.6);
  --stickyFooterBg: rgba(0,0,0,0.05);
  --backdropFill: rgba(0, 0, 0, 0.2);

  // Text
  --textColor: black;
  --textIconColor: #666;
  --textMutedColor: #999;
  --textSubtleColor: #bbb;
  --textAccentedColor: var(--primaryBg);
  --textErrorColor: rgb(206, 88, 88);

  // Icons
  --iconFill: var(--secondaryBg);
  --iconMutedFill: #ccc;
  --iconDarkFill: black;
  --iconAccentedFill: var(--primaryBg);
  --iconWarningFill: var(--textErrorColor);
  
  // Main Font Styling
  --baseFont: "Helvetica Neue";
  --headerFont: semi-bold;
  --textFont: regular;
  
  --baseFontSize: 1.4rem;
  --h1FontSizeEm: 2.0em;
  --h2FontSizeEm: 1.8em;
  --h3FontSizeEm: 1.6em;

  // General sizing
  --padHoriz: 0.5rem;
  --padHorizNeg: -0.5rem;
  --padVert: 0.5rem;
  --padVertNeg: -0.5rem;
  --gapHoriz: 1rem;
  --gapHorizNeg: -1rem;
  --gapVert: 0.5rem;
  --gapVertNeg: -0.5rem;
  --colGap: 1.5rem;
  --colGapNeg: 1.5rem;
  --borderRadius: 0.4rem;
  --borderRadiusSmall: 2px;

  --maxHeaderWidth: 70rem;
  --maxBodyWidth: 60rem;
  --maxContentWidth: 50rem;
  --maxFormWidth: 40rem;
  --maxContentWidthNarrow: 30rem;
  --headerHeight: 3rem;
  --footerHeight: 3.5rem;
  --formRowGap: 2rem;

  --imageTextBackgroundGradientHoriz: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  --imageTextBackgroundGradientVert: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));

  --scrollbarWidth: 15px;
  
  /*
    Styling scope is written in PascalCase, such as components. Variants added with dash:
    .ThisComponent-VariantName

    Styling on classes in a component/scope is lowercase and short and should
    always be defined in a scope:

    .Component {
      .body {}
    }

  */
  
  font: var(--baseFont);

}