.EditForm-User {
  // Do something
}

.formlib-FormRows {
  #instagramImages + .form-input-field {
    .IconButton.remove,
    .drop-message,
    .formlib-FileUploadWidget {
      display: none;
    }

    .image img {
      max-height: unset;
    }

    .formlib-DraggableList {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2px
    } 
    .ImageListField {
      & > .body {
        width: 100%;
      }
      & > .footer {
        display: none;
      }
    }
  }

}