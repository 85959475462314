@mixin shadowBlockerRight() {
  /* Shadow blocker */
  &::after {
    content: ' ';
    position: absolute;
    right: -4px;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #fbfbfb;
    border-left: 1px solid #eee;
  }
}