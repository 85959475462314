/* RESET https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */ 
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
// article > * + * {
//   margin-top: 1em;
// }

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  margin: 0; // Checkboxes get a margin for some reason
}

/* Remove outline on input elements in chrome */
input:focus, textarea:focus, select:focus{
  outline: none;
}

/* /END RESET */

/* Custom fonts */
// @font-face {
//   font-family: 'Gilroy';
//   src:
//     local('Gilroy-Light'),
//     local('Gilroy Light'),
//     url(./fonts/Gilroy-Light.otf) format('opentype');
//   font-weight: 200;
// }

caption {
  display: block;
}

body {
  overflow: hidden;
}