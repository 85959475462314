.ListItemUser {
  & > .body {
    position: relative;
    padding-left: 3.5rem;
  }
  .AvatarImageFieldWidget.avatar-Field {
    position: absolute;
    top: 50%;
    left: 0;
    width: 2.5rem;
    height: auto;
    transform: translateY(-50%);
  }
}