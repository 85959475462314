.EditForm-Invitation {
  .EditForm-InvitationLink {
    h3 {
      font-weight: 400;
    }
    p {
      margin-bottom: 0;
    }
    margin-bottom: 3rem;
  }
}