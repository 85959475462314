.AdminPageTemplate {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden scroll;

  & > .body {
    width: 100%;
    max-width: var(--maxContentWidth);
    margin: 0 auto;
    padding: var(--padVert) var(--padHoriz);
    flex-grow: 1;
  }

  .actionbar-container {
    position: sticky;
    flex-shrink: 0;
    .ButtonBar {
      margin: 0 auto;
    }
  }

  .filter-container {
    top: var(--headerHeight);
    width: 100%;
  
    .formlib-FormRows {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      max-width: var(--maxBodyWidth);
    }

    .form-row {
      margin-bottom: 0;
    }
  
    #search__Row {
      min-width: 15rem;
      flex: 0 0;
    
      .form-input-label {
        display: none;
      }
    
      #search__Field {
        border-radius: 2em;
        padding: var(--padVert) calc(var(--padHoriz) * 2);
        border: 1px solid var(--borderMutedColor);
      }
    }
    

  
    .form-nav,
    form {
      padding: 8px 0 0;
      background: var(--stickyHeaderBg);
    }
  
    .form-nav {
      justify-content: center;
    }
  
    .form-group {
      margin-bottom: 8px;
    }
  }
  
}