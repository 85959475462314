@import 'app-entity-Media/index.scss';

.CreatePage,
.EditPage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--pageBg);
  z-index: 9;

  & > .header {
    box-shadow: var(--shadow);
  }
  & > .body {
    max-width: var(--maxFormWidth);
    overflow: hidden visible;
  }

  & > .footer {
    box-shadow: var(--shadow);

    .Toolbar{
      max-width: var(--maxFormWidth);

      .leading {
        gap: 1.5em;
      }
    }

    .Dropdown {
      box-shadow: var(--shadow);
    }
  }
}