.publPage ul.publPriceList {
  max-width: 30rem;
  margin: 1rem auto;
  padding: 0;
  list-style: none;


  li {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--publPriceListBorderColor);

    &:last-child {
      border-bottom: 0;
    }

    .body {
      flex-shrink: 1;
      width: 100%;
    }
    .trailing {
      flex-shrink: 0;
    }
  }

}