
.MediaSelectItem {
  position: relative;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  width: 100%;

  &.loaded {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }

  & > caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: var(--padVert) var(--padHoriz);
    font-size: 0.75rem;
    color: white;
    background: var(--backdropFill);
    text-align: center;
    line-height: 1.1em;
    font-weight: 400;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 var(--padHoriz);
    font-size: 0.75rem;
    background: var(--imageTextBackgroundGradientHoriz);

    caption {
      color: white;
      line-height: 1.1em;
      font-weight: 400;
    }
  }
  
}

.MediaSelectDetails {
  display: flex;
  flex-direction: column;

  & > .body {
    width: 100%;
  }

  & > .footer {
    background: none;
    padding: var(--padVert) var(--padHoriz);

    .Button {
      width: 100%;
      margin-bottom: 0 !important;
    }
  }

  .formlib-FormRows {
    .form-row {
      margin-bottom: 0.5rem;
    }
    
    .form-input-label {
      font-size: 0.6em;
      font-weight: 400;
    }
  
    .form-input {
      border: none;
      padding: 0;
      line-height: 1.2;
      height: auto;
      font-size: 0.8em;
    }
  }
}