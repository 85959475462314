.PublicForm-Invitation.body {
  padding-bottom: 10vh;
  justify-content: flex-start;

  .section.copy {
    flex-direction: column;
    max-width: 30rem;
    margin: 0 auto;
  }

  .Invitation-Message {
    font-weight: 100;
    margin: 1em 0;
  }

  .LoginWidget-Social {
    display: flex;
    padding: 1rem 0;
    margin: 0 auto;
    max-width: 35rem;
    justify-content: center;

    #FB-Button {
      margin: 0 auto 0.5rem;
    }

    @media (min-width: 576px) {
      _padding-top: 4rem;
    }
  }
}