/*
  Sticky
*/
.sticky-vert-wrapper {
  .sticky-vert {
    position: absolute;
    z-index: 9;
    top: 0;

    &.inactive {
      display: none!important;
    }
  }
  
  .hidden {
    visibility: hidden!important;
  }
}