@import 'influence-App/_mixins.scss';
@import 'app-entity-User/widgets/EditForm.scss';

.Dashboard {
  display: flex;
  height: 100vh;
  max-height: -webkit-fill-available;
  max-height: -fill-available;
  overflow: hidden auto;

  & > .AdminMenu {
    position: sticky;
    width: 100%;
    @include shadowBlockerRight();
  }

  & > .body {
    width: 100%;
    max-width: var(--maxFormWidth);
    padding-top: calc(var(--padVert) + 2rem);
    padding-bottom: calc(var(--padVert) + 1rem);
  }

  & > .footer.fixed {
    width: 100%;
    @include shadowBlockerRight();
    box-shadow: var(--shadow);
    overflow: visible;

    .Toolbar{
      width: 100%;
      max-width: var(--maxFormWidth);

      .leading {
        gap: 1.5em;
      }

      .trailing {
        color: var(--textMutedColor);
      }
    }
  }

  #instagramImages .placeholder-message {
    display: none;
  }
}

@media (max-width: 576px) {
  .isAdminRoot .Dashboard {
    display: none;
  }
}


.PageLogo {
  position: fixed;
  right: 1rem;
  bottom: 0;
  width: 5rem;
  color: var(--primary);
  transition: transform 0.3s ease-in-out;

  &:hover {
    color: #618EC5;
    transform: scale(1.05);
  }
}

/**
 * Profile content
 */

.Dashboard > .body {
  .AvatarImageFieldWidget {
    margin: 3em 0 2em;
  }
  .Onboarding-Avatar {
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    width: auto;
  }
}
