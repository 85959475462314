:root {
  font-family: 'Open Sans', Roboto, sans-serif;
  font-weight: 100;
}

a,
a:hover,
a:link,
a:active {
  color: var(--linkFg);
  text-decoration: none;
  background-color: initial;
  
}
a:hover {
  text-decoration: underline;
}

.body,
.section {
  p {
    line-height: 1.75em;
    margin-bottom: 1.5em;
  }
}

.form-row {
  label {
  }
}

.App {
  h1,
  h2,
  h3,
  h4 {
    font-weight: 400;
    margin: 0.5em 0;
  }

  p {
    margin: 0 0 0.5em;
  }

  .Mobiledoc {
    h1,
    h2 {
      margin-top: 1.5em;
    }
    h3,
    h4 {
      margin-top: 1em;
    }
  }
}