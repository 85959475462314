.formlib-DraggableList {
  position: relative;

  .formlib-Draggable {
    transition: all 0.1s ease-in;
    &.source {
      opacity: 0.6;
    }
    &.insertBefore {
      margin-left: 3rem;
    }
    &.insertAfter {
      margin-right: calc(var(--padHoriz) + 3rem);
    }
    &.hideSource {
      opacity: 0.2;
    }
  }

  .debugMarkerLeft,
  .debugMarkerRight {
    position: absolute;
    top: 0;
    height: 1rem;
    width: 3px;
  } .debugMarkerLeft {
    background: red;
  } .debugMarkerRight {
    background: green;
  }
  .debugMarkerIndex {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: black;
    color: white;
  }
  .debugDragMarker {
    position: absolute;
    background-color: black;
    width: 3px;
    height: 100%;
    top: 0;
  }
}

.formlib-Draggable--Animation {
  &-enter {
    opacity: 0;
    width: 3rem; /* size of placeholder */
  }
  &-enter-active {
    transition: all 1.5s ease-out;
  }
  &-enter-end {
    opacity: 1;
  }

  &-leave {
    opacity: 1;
  }
  &-leave-active {
    transition: all 1.1s ease-in;
  }
  &-leave-end {
    opacity: 0;
    width: 0;
  }
}