:root {
  --transitionModalSheetEnter: all 0.3s ease-in;
  --transitionModalSheetLeave: all 0.2s ease-in;
}

.ModalSheet--Animation {
  &-enter-end,
  &-leave {
    opacity: 1;
  }
  
  &-leave-active {
    transition: var(--transitionModalSheetLeave);
  }

  &-enter-active {
    transition: var(--transitionModalSheetEnter);
  }
  
  &-enter,
  &-leave-end {
    opacity: 0;
    height: 0;
  }
}