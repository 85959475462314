@import './Page.scss';
@import './Masonry.scss';

.grid {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(15rem, 1fr));
  overflow-x: hidden;
}


.media {
  display: flex;
  flex-direction: column;

  &.horizontal {
    flex-direction: row;
  }
}

.body {
  display: block;
  margin: 0 auto;
  padding: var(--padVert) var(--padHoriz);
}

.section {
  display: flex;
  flex-direction: column;

  &.has-columns {
    flex-direction: row;
    gap: var(--gapHoriz);

    & > * {
      flex-grow: 1;
      flex-basis: 0;
    }

    @media (max-width: 576px) {
      flex-direction: column;
  
      .column {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &.sticky {
    position: sticky;
  }
}

.footer {
  display: flex;
  width: 100%;
  height: var(--footerHeight);
  transition: transform 0.2s ease-in;

  &-sticky {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 1;

    .footer {
      background-color: white; /* TODO: Should be variable */
      pointer-events: all;
    }
  }

  &.fixed {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: white; /* TODO: Should be variable */
    flex-shrink: 0;
  }

  &.hide {
    transition: transform 0.4s ease-in;
    transform: translateY(100%) scaleY(0);
  }
}

.header {
  display: flex;
  width: 100%;
  transition: transform 0.2s ease-in;
  height: var(--headerHeight);

  &-sticky {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 1;

    .header {
      background-color: white; /* TODO: Should be variable */
      pointer-events: all;
    }
  }

  &.fixed {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    background-color: white; /* TODO: Should be variable */
    flex-shrink: 0;
  }

  &.hide {
    transition: transform 0.5s ease-in;
    transform: translateY(-100%);
  }
}

.trailing {
  margin-left: auto;
}