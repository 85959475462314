.MessageCanvas {
  --canvasBg: var(--pageBg);
  --canvasFg: var(--iconMutedFill);
  --canvasPadVert: 2rem;
  --canvasPadHoriz: 2rem;
  --canvasArrowFill: var(--canvasFg);
  --canvasArrowSize: 2rem;
  --canvasArrowInnerSize: 1rem;
  --canvasArrowSpacer: 4px;


  display: flex;
  position: absolute;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  .body {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: var(--canvasPadVert) var(--canvasPadHoriz);
    background-color: var(--canvasBg);
    border-radius: var(--borderRadius);
    color: var(--canvasFg);
    font-weight: 400;

    h1, h2, h3, h4 {
      font-weight: 600;
    }

    *:last-child {
      margin-bottom: 0;
    }
    
  }

  &.arrowBottomCenter .body::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    transform: translate(-50%, calc(100% + var(--canvasArrowSpacer)));
    border-top: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-right: var(--canvasArrowInnerSize) solid transparent;
    border-left: var(--canvasArrowInnerSize) solid transparent;
  }

  &.arrowTopCenter .body::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    transform: translate(-50%, calc(-100% - var(--canvasArrowSpacer)));
    border-bottom: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-right: var(--canvasArrowInnerSize) solid transparent;
    border-left: var(--canvasArrowInnerSize) solid transparent;
  }

  &.arrowLeftCenter .body::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    transform: translate(calc(-100% - var(--canvasArrowSpacer)), -50%);
    border-right: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-top: var(--canvasArrowInnerSize) solid transparent;
    border-bottom: var(--canvasArrowInnerSize) solid transparent;
  }

  &.arrowRightCenter .body::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: var(--canvasArrowSize);
    height: var(--canvasArrowSize);
    transform: translate(calc(100% - var(--canvasArrowSpacer)), -50%);
    border-left: var(--canvasArrowInnerSize) solid var(--canvasArrowFill);
    border-top: var(--canvasArrowInnerSize) solid transparent;
    border-bottom: var(--canvasArrowInnerSize) solid transparent;
  }
}