body {
  overflow: hidden;
}
.App > .content {
  height: 100vh;
  width: 100vw;
}

.Modal.MessageModal {
  .content {
    margin-top: 3rem;
    max-width: 35rem;
    height: auto;
    
    & > .header {
      text-align: center;
      font-size: 1.25em;
    }

    & > .body {
      display: flex;
      flex-direction: column;
      padding: var(--padVert) var(--padHoriz);
      font-size: 1.1em;
      max-width: 30rem;
      align-self: center;
      justify-content: center;
      height: calc(20rem - var(--modalFooterHeight) - var(--modalHeaderHeight));
      margin-left: auto;
      margin-right: auto;
    }
    & > .footer {
      .ButtonBar {
        justify-content: center;
        margin-bottom: var(--padVert);
        width: 100%;

        button {
          padding: 1rem 2.5rem;
        }
      }
    }
  }
}

.Fullpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  // Respect status and address bar
  max-height: -webkit-fill-available;
  max-height: -fill-available;
  overflow: hidden auto;
}