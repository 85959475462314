
/**
 * Success message
 */
 .SuccessMessage {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 0.95;
}

.SucessAnimation {
  &-enter {
    opacity: 0;
  }
  &-enter-active {
    transition: all 0.15s ease-in;
  }
  &-enter-end {
    opacity: 1;
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }

  &-leave-active {
    transition: all 0.65s ease-out;
  }
  &-leave-end {
    opacity: 0;
  }
}

/**
 * Some genenral animations
 */

// Simple fade in animation
.FadeIn-enter {
  opacity: 0;
}
.FadeIn-enter-active {
  transition: opacity 0.5s ease-in;
}
.FadeIn-enter-end {
  opacity: 1;
}
.FadeIn-leave {
  opacity: 1;
}
.FadeIn-leave-active {
  transition: opacity 0.5s ease-in;
}
.FadeIn-leave-end {
  opacity: 0;
}

// Appear animation with fade and height
.Appear-enter {
  opacity: 0;
  height: 0;
}
.Appear-enter-active {
  transition: opacity 0.5s ease-out, height 0.3s ease-out;
}
.Appear-enter-end {
  opacity: 1;
}
.Appear-leave {
  opacity: 1;
}
.Appear-leave-active {
  transition: opacity 0.4s ease-out, height 0.3s ease-out;
}
.Appear-leave-end {
  opacity: 0;
  height: 0;
}

// Page navigation animation
.PageNav {
  &-enter {
    opacity: 0;
    transform: translateX(30%);
  }
  &-enter-active {
    transition: opacity 0.7s ease-out, transform 0.5s ease-out;
  }
  &-enter-end {
    opacity: 1;
    transform: translateX(0);
  }
  /* ----------------------------- */
  &-leave {
    opacity: 1;
  }
  &-leave-active {
    position: absolute;
    width: 100%;
    transition: opacity .7s ease-out, transform 0.5s ease-out;
  }
  &-leave-end {
    opacity: 0;
    transform: scale(1.3);
  }
}