.Onboarding {
  padding-top: 3.5rem;
  background-color: var(--pageBg);

  #Page-Logo {
    position: absolute;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    width: 15rem;
    top: 1rem;
    opacity: 1;
    transition: 0.2s ease-in;
  }

  .Onboarding-Content {
    width: 100%;
    max-width: var(--maxFormWidth);
    margin: 0 auto;

    .body {
      padding-bottom: var(--footerHeight);
    }
  }

  h1 {
    text-align: center;
  }

  h2 {
    margin: 2.5rem 0 2rem;
    text-align: center;
    width: 100%;
  }

  h3 {
    text-align: center;
    font-size: 1.5em;
    width: 100%;
  }

  h4 {
    font-size: 1em;
  }

  .Onboarding-Link-Button {
    margin: 1rem auto;
  }

  .Onboarding-ActionContainer {
    display: flex;
    padding: 2rem 0 0.5rem;
    margin: 0 auto;
    max-width: 35rem;

    #FB-Button {
      margin: 0 auto 0.5rem;
    }

    @media (min-width: 576px) {
      padding-top: 4rem;
    }
  }

  .Onboarding-Action-Button {
    min-width: 15rem;
  }

  .AvatarImageFieldWidget {
    margin: 3em 0 2em;
  }
  .Onboarding-Avatar {
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    width: auto;
  }


  .Onboarding-AcceptTerms {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    text-align: center;

    @media (min-width: 576px) {
      flex-direction: row;
      border-top: 1px solid #eee;

      .form-row {
        margin-bottom: 0.5rem;
      }
    }

    .form-row {
      position: relative;
    }
    .form-input-checkbox {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      margin: 0;
    }
    .form-input-label {
      margin-left: 1.5rem;
      vertical-align: 1px;
    }

  }
}


// Page navigation animation
.OnboardingPageNav {
  &-enter .Onboarding-Content {
    opacity: 0;
    transform: translateX(50%);
  }
  &-enter-active .Onboarding-Content {
    transition: opacity 0.4s ease-out, transform 0.5s ease-in;
  }
  &-enter-end .Onboarding-Content {
    opacity: 1;
    transform: translateX(0);
  }
  /* ----------------------------- */
  &-leave .Onboarding-Content {
    opacity: 1;
    transform: translateX(0);
  }
  &-leave-active {
    position: absolute;
    top: 0;
    width: 100%;
    .Onboarding-Content {
      transition: opacity .4s ease-out, transform 0.5s ease-in;
    }
  }
  &-leave-end .Onboarding-Content {
    opacity: 0;
    transform: translateX(-100%);
  }
}