.MediaCard {
  position: relative;

  .imagePlaceholder {
    width: 100%;
    padding: calc(25% - 0.5em) 1rem;
    background-color: var(--inputRowLineColor);
    text-align: center;
    color: #eee;
    font-size: 2em;
    font-weight: 500;
  }

  .editButton {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.Modal.MediaCard-Edit {
  --modalFooterHeight: 10rem;

  .content > .body {
    width: var(--maxFormWidth);
  }

  .content > .footer.fixed {
    height: var(--modalFooterHeight);
    box-shadow: var(--shadow);
  }

  .placeholder,
  .imageEditPlaceholder,
  .imageCollageEditPlaceholder {
    background-color: var(--inputRowLineColor);
    text-align: center;
    color: #eee;
    font-size: 2em;
    font-weight: 500;
  }

  .placeholder,
  .imageEditPlaceholder {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .imageCollageEditPlaceholder {
    width: 100%;
    padding: calc(25% - 0.5em) 1rem;
  }

  .Gallery.form-row {
    margin-bottom: var(--formRowGap);
  }

  .editButton {
    position: absolute;
    top: 0;
    right: 0;
  }

  .imagesBar {
    .formlib-FormRows {
      max-width: 100%;
    }

    .form-input-label {
      display: none;
    }

    .form-row-list,
    .form-input-field,
    .ImageListField {
      height: 100%;
    }

    .ImageListField {
      padding: var(--padVert) var(--padHoriz);

      & > .body {
        flex-grow: 1;
        height: 100%;
      }

      & > .footer {
        height: 100%;
        width: auto;
      }
    }

    .form-input-feedback {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 9;
    }
  }

}
