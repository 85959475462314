.tab-page,
.page {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // Respect status and address bar
  max-height: -webkit-fill-available;
  max-height: -fill-available;
  overflow: hidden auto;

  & > .header,
  & > .footer {
    flex-shrink: 0;
  }
  & > .body {
    flex: 1 0 auto;
    width: 100%;
  }
}

.PageAnimation {
  &-enter-active {
    transition: opacity 0.6s ease-out, transform 0.5s ease-out;
  }
  &-leave-end,
  &-enter {
    opacity: 0;
    transform: translateX(50%);
  }
  &-leave,
  &-enter-end {
    opacity: 1;
    transform: translateX(0);
  }
  &-leave-active {
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;
  }
}