/* Bootstrap overrides */
.invalid-feedback {
  display: block!important;
}

/* DateField Widget */
.form-input-date {
  button.unset {
    opacity: 1;
    margin-left: 0.5rem;
    color: red;
    cursor: pointer;
    align-self: center;
    font-size: 0.75em;

    .form-input-datetime.noValue & {
      opacity: 0;
      margin-left: 0;
      width: 0;
    }
  }

  input {
    min-width: 5.5em;
  }
  input:first-child {
    min-width: 6.5em;
  }

  .Dropdown {
    --dropdownHoverBg: transparent;
    --dropdownHoverFg: inherit;
  }
}

.form-input-datetime {
  display: flex;
  flex-direction: row;
  gap: var(--gapHoriz);
  align-items: center;
  
  .form-input {
    width: 6.5em;
  }
}

.DateSelector {
  
  .header {
    .yearMonth {
      white-space: nowrap;
      flex-grow: 1;
      text-align: center;
  
      button {
        margin: 0;
        font-size: 1.5em;
      }
    }
  }

  .footer {
    padding: 0.5rem;
    text-align: center;
    background-color: #f7f7f7;
    border-top: 1px solid #ebebeb;
  }
  
  .Calendar thead {
    width: 100%;
    th {
      text-align: center;
      color: #aaa;
      padding-bottom: 0.5rem;
    }
    border-bottom: 1px solid var(--borderMutedColor);

  }
  .Calendar tbody {
    tr:first-child td {
      padding-top: 0.5rem;
    }

    td {
      text-align: center;
      color: #666;

      &.text-muted button {
        color: #bbb !important;
      }
      
      &.today button {
        position: relative;
        border: 0;
        font-weight: 500;
        border-radius: var(--borderRadiusSmall);

        small {
          position: absolute;
          font-size: 8px;
          line-height: 12px;
          top: -2px;
          left: 50%;
          transform: translateX(-50%);
          text-transform: uppercase;
          font-weight: 500;
        }
      }
      
      &.selected button {
        background-color: var(--primaryBg);
        color: var(--primaryFg);
      }
      
    }
  }

  .YearSelect {
    display: flex;
    flex-direction: row;
    gap: var(--gapHoriz);

    ul {
      display: grid;
      grid: repeat(3, auto) / 1fr 1fr 1fr;
      margin: 0;
      padding: 0;

      li {
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }
  }


  
  .DateFieldItem:hover,
  .DateFieldItem--selected {
    color: var(--primaryFg) !important;
    background-color: var(--primaryBg);
    border-radius: var(--borderRadiusSmall);
  } 

  
  .nav-item {
    flex: 0 1;
  }
  
  .nav-link:hover {
    border-radius: var(--borderRadiusSmall);
    color: var(--primaryFg);
    background-color: var(--primaryBg);
  }
  
  .DateFieldMonth {
    flex: 1 0;
    text-align: center;
  }

  .DateField-YearSelect {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 1rem;
  }

  .DateField-YearSelect-day {
    list-style: none;
    padding: 0.5rem;
    color: var(--primary);
    cursor: pointer;
  }

  .DateField-YearSelect-minus,
  .DateField-YearSelect-plus {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0.5rem;

    i {
      align-self: center;
      font-weight: bold;
      font-style: normal;
      color: var(--primary);
      cursor: pointer;
    }
  }
  .DateField-YearSelect-minus { left: 0 }
  .DateField-YearSelect-plus { right: 0 }
}  


/**
*  DateTimeWidget
*/
.input-group.form-input-datetime {
  display: block;
  
  & > .formlib-DateTime_date {
    display: inline-block;
    width: 7.5em;
    transition: all 0.15s linear;
  }
  &--noValue > .formlib-DateTime_date {
    width: 13.5em!important;
    border-radius: var(--borderRadiusSmall)!important;
  }
  
  & > .formlib-DateTime_time {
    display: inline-block;
    width: 6em;
    transition: all 0.15s linear;
  }
  &--noValue > .formlib-DateTime_time {
    width: 0!important;
    padding-right: 0!important;
    padding-left: 0!important;
    border-width: 0!important;
  }
}



/*
    ActionBar
*/
.ActionBar,
.formlib-ActionBar {
  background-color: rgba(255,255,255,0.8);
  z-index: 1;
  width: 100%;
  border-top: 1px solid var(--inputBorderColor);

  .btn-success {
    margin-right: 1rem;
    color: var(--white);
  }
}
.formlib-StickyActionBar {
  position: fixed;
  left: 0;
  box-shadow: var(--shadow);
  
  &--hidden {
    display: none;
  }
}


/**
 * FileUploadWidget
 */
// TODO: Since the DragNDroppable is a wrapper it shouldn't have styling
.DragNDroppable {
  display: flex;
  align-items: center;
  flex-grow: 1;
  
  cursor: pointer;
  transition: background-color 0.15s ease-in, color 0.15s ease-in;
  
  &.DragNDroppable--drag {
    background-color: rgba(0,0,0,0.4);
    border-color: rgba(0,0,0,0);
  }
  
  &.DragNDroppable--drag-hover {
    background-color: rgba(0,0,0,0.6);
    border-color: rgba(0,0,0,0);
  }
  
  &.DragNDroppable--drag .placeholder,
  &.DragNDroppable--drag-hover .placeholder {
    color: white;
  }
}

.formlib-ErrorMsg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdropFill);
  
  .body {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    transform: translateY(-50%);
  }
  
  .body p {
    font-size: 0.85em;
    color: white;
    margin-bottom: 0.6em;
  }
}

  
.formlib-FileUploadWidget {
  .form-input-file {   
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    // NOTE: This hides the input, doing it to avoid overriding other buttons, but perhaps this
    // needs to be fixed some other way.
    z-index: -1;
  }
  
  .placeholder {
    position: relative;
    margin: 0.5rem 1rem 0;
    font-size: 0.9em;
    color: var(--textMutedColor);

    top: auto;
    left: auto;
    position: inherit;
    transform: none;
    width: 100%;
  }
}


/*
  ImageField
*/

.ImageField.card {
  flex-direction: row;
  align-items: center;
  border: none;
  margin-bottom: 0;
  border-radius: 0;
  
  .card-img {
    border-radius: 0;
    // margin-right: 0.5rem;
    overflow: hidden;
    object-fit: contain;
    max-height: 10rem;
    max-width: 30rem;
    width: auto;
    margin-right: auto!important;
  }
  
  .card-title {
    font-weight: 100;
    font-size: 0.85em;
    margin: 0;
  }
  
  .card-subtitle {
    margin: 0;
    margin-top: 0.2em;
  }
  
  .card-text {
    font-size: 0.8em;
    margin: 0;
    margin-top: 0.2em;
  }
  
  .card-body {
    padding: 0;
    height: 100%;
  }
  
  .card-footer {
    padding: 0;
    background: none;
    border: none; 
  }
  
  .ImageField-Action {
    line-height: 0.8em;
    vertical-align: bottom;
  }
}

.form-input-object-field {
  display: block;
}

// .App is a hack around bootstrap, should remove when bootstrap is gone
.formlib-FormRows {
  width: 100%;
  margin: 0 auto;
  
  .form-row {
    max-width: var(--maxFormWidth);
    margin: 0 auto var(--formRowGap);
    flex-grow: 1;
  }
  
  .form-input {
    font-weight: 200;
    font-size: 1em;
    padding: 0.1rem 0.5rem;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid var(--inputBorderColor);
    width: 100%;

    &.isNotValid {
      border-bottom: 2px solid var(--textErrorColor);
    }
  }

  .form-input-textarea {
    font-size: 1.3em;
    line-height: 1.15em;
    min-height: 4em;
    background-image: linear-gradient(transparent, transparent calc(1.15em - 1px), var(--inputRowLineColor) 0px);
    background-size: 100% 1.15em;
    border-bottom: none;
  }
  select.form-input {
    font-size: 1.3em;
  
    option {
      font-size: 0.9em;
    }
  }
  
  .form-input-label {
    color: var(--textMutedColor);
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 0;
  }

  .form-input-help {
    color: var(--textMutedColor);
    font-weight: 200;
    font-size: 0.9em;
  }
  
  .form-input-feedback {
    font-weight: 200;
    font-size: 0.9em;

    &.isNotValid {
      color: var(--textErrorColor);
    }
  }

  .form-row-list,
  .form-row-object {
    & > .form-input-label {
      border-bottom: 1px solid var(--borderMutedColor);
      border-left: 5px solid var(--borderMutedColor);
      padding-left: 0.5em;
      display: block;
      font-size: 1.5em;
      font-weight: 200;
      margin: 1em 1rem 1em 0;
    }

    & > .form-input-feedback.isNotValid {
      padding: var(--padVert) var(--padHoriz);
      border-radius: var(--borderRadiusSmall);
      background-color: var(--errorBg);
      color: var(--errorFg);
      text-align: center;
    }
  }

  .form-row-object {
    & > .form-input-field > .form-input-object-field {
      border-left: 2px solid var(--borderMutedColor);
      padding-left: 1rem;
    }
  }
  
  .form-row-list {    
    .formlib-ListFieldRow {
      display: flex;
      
      .formlib-ListFieldRowDeleteBtn.btn-danger {
        background: none;
        color: var(--textErrorColor);
        height: 2.5em;
        border: none;
        align-self: center;
      }
      
      .formlib-ListFieldRowDeleteBtn:hover {
        background: var(--errorBg);
        color: var(--errorFg);
      }
      
      & > .form-row {
        flex-grow: 1;
      }
    }
  }
  
  .form-input-label.checkbox {
    position: relative;
    padding-left: 2rem;
    line-height: 1em;
    text-align: left;
    
    .form-input-checkbox {
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.formlib-DragItem {
  position: relative;

  .formlib-DragHandle {
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: url(./img/icons/drag_handle.svg);
    background-size: contain;
    cursor: grab;
  }
}

.form-row.IDateField {
  .DateFieldSelector {
    width: 20rem;
  }

  .form-input-field > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gapHoriz);

    .form-input {
      width: 7em;
    }
    
    & > a {
      flex-grow: 1;
    }
  }
}


/**
 * Animate error messages
 */
 .formlib-ErrorMsg--Animation {
  &-leave {
    opacity: 1;
  }
  
  &-leave-active {
    overflow: hidden;
    transition: height 0.3s ease-out, opacity 0.2s ease-in;
  }
  
  &-leave-end {
    height: 0;
    opacity: 0;
  }
  
  &-enter {
    height: 0;
    opacity: 0;
  }
  
  &-enter-active {
    transition: height 0.2s ease-out, opacity 0.5s ease-in;
  }
  
  &-enter-end {
    opacity: 1;
  }
}

/**
 * Animate rows and list rows
 */
.formlib-ListFieldRow--Animation,
.FormRow--Animation {
  &-leave {
    opacity: 1;
  }

  &-leave-active {
    overflow: hidden;
    transition: height 0.3s ease-out, opacity 0.2s ease-in;
  }
  
  &-leave-end {
    height: 0;
    opacity: 0;
  }
  
  &-enter {
    height: 0;
    opacity: 0;
  }
  
  &-enter-active {
    transition: height 0.2s ease-out, opacity 0.5s ease-in;
  }
  
  &-enter-end {
    opacity: 1;
  }
}
