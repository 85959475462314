.Toolbar {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  max-width: var(--maxBodyWidth);
  margin: 0 auto;
  
  &.has-shadow {
    box-shadow: var(--shadowMuted);
    /* Shadow blocker */
    &::before {
      content: '';
      position: absolute;
      z-index: -1; /* To avoid blocking content */
      top: 0;
      bottom: 0;
      left: -5px;
      right: -5px;
      background-color: var(--pageBg);
    }
  }

  & > .hidden {
    visibility: hidden;
  }

  & > .trailing,
  & > .leading {
    flex-shrink: 1;
    flex-grow: 0;
    min-width: 10rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gapHoriz);
  }
  & > .trailing {
    justify-content: flex-end;
    margin-left: auto;
  }

  & > .body {
    flex-grow: 1;
    flex-shrink: 0;

    display: flex;
    flex-direction: row;
    align-items: center; /*  vert align */
    justify-content: center; /* horiz align */
  }
}

.Toolbar.align-center {
  .trailing,
  .leading {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .leading {
    left: 0;
  }
  .trailing {
    right: 0;
  }
}