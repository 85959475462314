.App .publStaffIntro {
  padding-top: 3rem;

  .publAvatar {
    border-radius: 50%;
    border: 2px solid #fff;
    max-width: 20rem;
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-100% + 3rem));
  }

  h1 {
    text-transform: unset;
    color: #000;
    font-size: 2em;
    font-weight: 100;
    margin-bottom: 0;
  }
  
  h2 {
    font-family: "Savoye LET", script;
    text-transform: unset;
    color: #000;
    font-size: 2em;
    font-weight: 100;
    margin-top: 0;
  }

  pre {
    max-width: 30rem;
    text-align: center;
    margin: 0 auto 2em;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.8rem;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

.App .publInstaGrid {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(20rem, 1fr) );
  gap: 2px;
  margin-bottom: -2rem;

  .image-container {
    position: relative;
    overflow: hidden;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}