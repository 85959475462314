:root {
  --transitionModalEnter: transform 0.4s ease-out, opacity 0.4s ease-in;
  --transitionModalLeave: transform 0.3s ease-in, opacity 0.2s ease-in;
  --transitionModalBackdropEnter: opacity 0.4s ease-in;
  --transitionModalBackdropLeave: opacity 0.4s ease-in;
  --modalHeaderHeight: 3.5rem;
  --modalFooterHeight: 3.5rem;
}

.ModalSheet,
.Modal {
  position: fixed;
  display: flex;
  top: 0;
  width: 100vw;
  height: 100vh;

  & > .content {
    display: flex;
    position: relative;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    pointer-events: auto;
    background-color: var(--pageBg);
    background-clip: padding-box;
    border-radius: var(--borderRadius);
    outline: 0;
  
    & > .header {
      border-bottom: 1px solid var(--borderMutedColor);
      padding: var(--padVert) var(--padHoriz);
      height: var(--modalHeaderHeight);
      left: 0;
      right: 0;
      
      & > .Toolbar,
      & > .ButtonBar {
        max-width: 100%;
        margin-top: var(--padVertNeg);
      }

    }

    & > .body {
      overflow: visible;
      width: 100%;
    }

    & > .footer {
      height: var(--modalFooterHeight);
    }
  }

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--backdropFill);
  }
}

/*************************
** Modal Animations CSS **
**************************/
.Modal {
  & > .backdrop {
    &.fade {
      transition: var(--transitionModalBackdropLeave);
      opacity: 0;
    }
    &.show {
      transition: var(--transitionModalBackdropEnter);
      opacity: 1;
    }
  }
  & > .content {
    &.fade {
      transition: var(--transitionModalLeave);
      opacity: 0.5;
      transform: translateY(-50%);
    }
    &.show {
      transition: var(--transitionModalEnter);
      opacity: 1;
      transform: translateY(0);
    }
  }
}
