.formlib-UploadProgress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdropFill);
  z-index: 1;
  
  .progress {
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    height: 1rem;
    transform: translateY(-50%);
    background-color: var(--borderMutedColor);
    opacity: 0.9;
    overflow: hidden;

    .bar {
      height: 100%;
      background-color: var(--iconAccentedFill);
      transition: width 0.3s ease-in-out; color: white;
    }
  }

  .body {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    transform: translateY(-50%);
  }
}