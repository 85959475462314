.Modal.ModalCloseWarning .content {
  margin-top: 3rem;
  max-width: 35rem;
  height: 20rem;
  
  & > .header {
    text-align: center;
    font-size: 1.25em;
  }

  & > .body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(--padVert) var(--padHoriz);
    font-size: 1.1em;
    max-width: 30rem;
    align-self: center;
    justify-content: center;
    margin: 0 auto;
    height: calc(20rem - var(--modalFooterHeight) - var(--modalHeaderHeight));
  }

  & > .footer {
    padding: var(--padVert) var(--padHoriz);
  }
}